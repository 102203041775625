// Please include here global styles only specific to CA application
@import 'firefly/styles/tokens/snp-light/scss/css-variables';
@import 'firefly/styles/mixin';

body {
  height: 100%;
  width: 100%;
  position: fixed;
  margin: 0;
}

// PWA styles for touchscreen devices
@media (display-mode: standalone) and (pointer: coarse) {
  body {
    position: static;
    height: 100dvh;
  }
}
