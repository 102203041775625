
/**
* Assets tokens
*/
$assets-path: "./../../../../../assets/general/";

/**
* Border tokens
*/
$border-style-solid: solid;
$border-style-dashed: dashed;
$border-width-1: 1px;
$border-width-2: 2px;

/**
* Border-radius css variables
*/
$border-radius-1: var(--border-radius-1);
$border-radius-2: var(--border-radius-2);
$border-radius-3: var(--border-radius-3);
$border-radius-4: var(--border-radius-4);
$border-radius-100: var(--border-radius-100);

/**
* Grid-breakpoint tokens
*/
$grid-breakpoint-xs: 360px;
$grid-breakpoint-sm: 600px;
$grid-breakpoint-md: 876px;
$grid-breakpoint-lg: 1024px;
$grid-breakpoint-xl: 1280px;
$grid-breakpoint-xxl: 1536px;
$grid-breakpoint-xxxl: 1920px;

/**
* Container-max-width tokens
*/
$container-max-width-xs: 328px;
$container-max-width-sm: 552px;
$container-max-width-md: 828px;
$container-max-width-lg: 976px;
$container-max-width-xl: 1232px;
$container-max-width-xxl: 1488px;
$container-max-width-xxxl: 1872px;

/**
* Font tokens
*/
$font-base-size: 0.875rem;
$font-base-line-height: 1.25rem;
$font-heading-70-size: 2rem;
$font-heading-70-line-height: 3rem;
$font-heading-50-size: 1.5rem;
$font-heading-50-line-height: 2rem;
$font-heading-40-size: 1.375rem;
$font-heading-40-line-height: 2rem;
$font-heading-30-size: 1.125rem;
$font-heading-30-line-height: 1.75rem;
$font-heading-20-size: 0.75rem;
$font-heading-20-line-height: 1rem;
$font-heading-70-size-mobile: 1.375rem;
$font-heading-70-line-height-mobile: 1.65rem;
$font-heading-50-size-mobile: 1.125rem;
$font-heading-50-line-height-mobile: 1.5rem;
$font-heading-40-size-mobile: 1.125rem;
$font-heading-40-line-height-mobile: 1.5rem;
$font-heading-30-size-mobile: 1rem;
$font-heading-30-line-height-mobile: 1.26315rem;
$font-subtitle-50-size: 1rem;
$font-subtitle-50-line-height: 1.5rem;
$font-subtitle-50-size-mobile: 1rem;
$font-subtitle-50-line-height-mobile: 1.26315rem;
$font-body-70-size: 0.875rem;
$font-body-70-line-height: 1.25rem;
$font-body-50-size: 0.75rem;
$font-body-50-line-height: 1rem;
$font-body-40-size: 0.6875rem;
$font-body-40-line-height: 0.875rem;
$font-body-30-size: 0.625rem;
$font-body-30-line-height: 0.75rem;
$font-link-70-size: 0.875rem;
$font-link-70-line-height: 1.25rem;
$font-link-50-size: 0.75rem;
$font-link-50-line-height: 1rem;
$font-icon-xxs: 0.75rem;
$font-icon-xs: 1rem;
$font-icon-sm: 1.25rem;
$font-icon-md: 1.5rem;
$font-icon-lg: 1.875rem;
$font-icon-xl: 2.1875rem;
$font-icon-xxl: 2.5rem;
$font-weight-normal: 400;
$font-weight-bold: 600;
$font-family: "Akkurat", sans-serif;

/**
* Zindex tokens
*/
$zindex-mobile-inner-error: 1150;
$zindex-toast: 1090;
$zindex-confirmation-modal: 1065;
$zindex-onesearch: 1055;
$zindex-top-navigation: 990;
$zindex-onesearch-backdrop: 980;
$zindex-left-navigation: 970;
$zindex-drawer: 960;
$zindex-suggester: 955;
$zindex-drawer-collapsed: 950;
$zindex-sub-navigation: 940;

/**
* Brand-assets-path tokens
*/
$brand-assets-path: "./../../../../../../assets/brandings/snp-light/";

/**
* Brand-assets-common-path tokens
*/
$brand-assets-common-path: "./../../../../../../assets/brandings/common/light/";

/**
* Brand-icon-assets-path tokens
*/
$brand-icon-assets-path: "./../../../../../../assets/brandings/common/light/icons/";

/**
* Brand-icon-layouts-assets-path tokens
*/
$brand-icon-layouts-assets-path: "./../../../../../assets/brandings/common/light/icons/";

/**
* Color css variables
*/
$color-primary-50: var(--color-primary-50);
$color-primary-100: var(--color-primary-100);
$color-primary-200: var(--color-primary-200);
$color-primary-300: var(--color-primary-300);
$color-primary-400: var(--color-primary-400);
$color-primary-500: var(--color-primary-500);
$color-primary-600: var(--color-primary-600);
$color-primary-700: var(--color-primary-700);
$color-primary-800: var(--color-primary-800);
$color-primary-900: var(--color-primary-900);
$color-primary-950: var(--color-primary-950);
$color-neutral-50: var(--color-neutral-50);
$color-neutral-100: var(--color-neutral-100);
$color-neutral-200: var(--color-neutral-200);
$color-neutral-300: var(--color-neutral-300);
$color-neutral-400: var(--color-neutral-400);
$color-neutral-500: var(--color-neutral-500);
$color-neutral-600: var(--color-neutral-600);
$color-neutral-700: var(--color-neutral-700);
$color-neutral-800: var(--color-neutral-800);
$color-neutral-900: var(--color-neutral-900);
$color-success-50: var(--color-success-50);
$color-success-100: var(--color-success-100);
$color-success-200: var(--color-success-200);
$color-success-300: var(--color-success-300);
$color-success-400: var(--color-success-400);
$color-success-500: var(--color-success-500);
$color-success-600: var(--color-success-600);
$color-success-700: var(--color-success-700);
$color-success-800: var(--color-success-800);
$color-success-900: var(--color-success-900);
$color-warning-50: var(--color-warning-50);
$color-warning-100: var(--color-warning-100);
$color-warning-200: var(--color-warning-200);
$color-warning-300: var(--color-warning-300);
$color-warning-400: var(--color-warning-400);
$color-warning-500: var(--color-warning-500);
$color-warning-600: var(--color-warning-600);
$color-warning-700: var(--color-warning-700);
$color-warning-800: var(--color-warning-800);
$color-warning-900: var(--color-warning-900);
$color-warning-950: var(--color-warning-950);
$color-danger-50: var(--color-danger-50);
$color-danger-100: var(--color-danger-100);
$color-danger-200: var(--color-danger-200);
$color-danger-300: var(--color-danger-300);
$color-danger-400: var(--color-danger-400);
$color-danger-500: var(--color-danger-500);
$color-danger-600: var(--color-danger-600);
$color-danger-700: var(--color-danger-700);
$color-danger-800: var(--color-danger-800);
$color-danger-900: var(--color-danger-900);
$color-default-50: var(--color-default-50);
$color-default-100: var(--color-default-100);
$color-default-200: var(--color-default-200);
$color-default-300: var(--color-default-300);
$color-default-400: var(--color-default-400);
$color-default-500: var(--color-default-500);
$color-default-600: var(--color-default-600);
$color-default-700: var(--color-default-700);
$color-default-800: var(--color-default-800);
$color-default-900: var(--color-default-900);
$color-accent-1-50: var(--color-accent-1-50);
$color-accent-1-100: var(--color-accent-1-100);
$color-accent-1-200: var(--color-accent-1-200);
$color-accent-1-300: var(--color-accent-1-300);
$color-accent-1-400: var(--color-accent-1-400);
$color-accent-1-500: var(--color-accent-1-500);
$color-accent-1-600: var(--color-accent-1-600);
$color-accent-1-700: var(--color-accent-1-700);
$color-accent-1-800: var(--color-accent-1-800);
$color-accent-1-900: var(--color-accent-1-900);
$color-accent-2-50: var(--color-accent-2-50);
$color-accent-2-100: var(--color-accent-2-100);
$color-accent-2-200: var(--color-accent-2-200);
$color-accent-2-300: var(--color-accent-2-300);
$color-accent-2-400: var(--color-accent-2-400);
$color-accent-2-500: var(--color-accent-2-500);
$color-accent-2-600: var(--color-accent-2-600);
$color-accent-2-700: var(--color-accent-2-700);
$color-accent-2-800: var(--color-accent-2-800);
$color-accent-2-900: var(--color-accent-2-900);
$color-accent-3-50: var(--color-accent-3-50);
$color-accent-3-100: var(--color-accent-3-100);
$color-accent-3-200: var(--color-accent-3-200);
$color-accent-3-300: var(--color-accent-3-300);
$color-accent-3-400: var(--color-accent-3-400);
$color-accent-3-500: var(--color-accent-3-500);
$color-accent-3-600: var(--color-accent-3-600);
$color-accent-3-700: var(--color-accent-3-700);
$color-accent-3-800: var(--color-accent-3-800);
$color-accent-3-900: var(--color-accent-3-900);
$color-accent-4-50: var(--color-accent-4-50);
$color-accent-4-100: var(--color-accent-4-100);
$color-accent-4-200: var(--color-accent-4-200);
$color-accent-4-300: var(--color-accent-4-300);
$color-accent-4-400: var(--color-accent-4-400);
$color-accent-4-500: var(--color-accent-4-500);
$color-accent-4-600: var(--color-accent-4-600);
$color-accent-4-700: var(--color-accent-4-700);
$color-accent-4-800: var(--color-accent-4-800);
$color-accent-4-900: var(--color-accent-4-900);
$color-accent-5-50: var(--color-accent-5-50);
$color-accent-5-100: var(--color-accent-5-100);
$color-accent-5-200: var(--color-accent-5-200);
$color-accent-5-300: var(--color-accent-5-300);
$color-accent-5-400: var(--color-accent-5-400);
$color-accent-5-500: var(--color-accent-5-500);
$color-accent-5-600: var(--color-accent-5-600);
$color-accent-5-700: var(--color-accent-5-700);
$color-accent-5-800: var(--color-accent-5-800);
$color-accent-5-900: var(--color-accent-5-900);
$color-accent-6-50: var(--color-accent-6-50);
$color-accent-6-100: var(--color-accent-6-100);
$color-accent-6-200: var(--color-accent-6-200);
$color-accent-6-300: var(--color-accent-6-300);
$color-accent-6-400: var(--color-accent-6-400);
$color-accent-6-500: var(--color-accent-6-500);
$color-accent-6-600: var(--color-accent-6-600);
$color-accent-6-700: var(--color-accent-6-700);
$color-accent-6-800: var(--color-accent-6-800);
$color-accent-6-900: var(--color-accent-6-900);
$color-base: var(--color-base);
$color-brand: var(--color-brand);

/**
* Shadow tokens
*/
$shadow-neutral-900-level-0-properties: 0 0 2px 0;
$shadow-neutral-900-level-0-opacity: 0.12;
$shadow-neutral-900-level-1-properties: 0 1px 5px 0;
$shadow-neutral-900-level-1-opacity: 0.08;
$shadow-neutral-900-level-2-properties: 0 1px 3px 0;
$shadow-neutral-900-level-2-opacity: 0.22;
$shadow-neutral-900-level-3-properties: 0 2px 5px 0;
$shadow-neutral-900-level-3-opacity: 0.16;
$shadow-neutral-900-level-4-properties: 0 2px 7px 0;
$shadow-neutral-900-level-4-opacity: 0.14;
$shadow-neutral-900-level-5-properties: 0 2px 10px 0;
$shadow-neutral-900-level-5-opacity: 0.14;
$shadow-neutral-900-level-6-properties: 0 4px 8px 0;
$shadow-neutral-900-level-6-opacity: 0.07;
$shadow-neutral-900-level-6-top-properties: 0 -4px 8px 0;
$shadow-neutral-900-level-6-top-opacity: 0.07;
$shadow-neutral-900-level-6-left-properties: -4px 0 8px 0;
$shadow-neutral-900-level-6-left-opacity: 0.07;
$shadow-neutral-900-level-6-right-properties: 4px 0 8px 0;
$shadow-neutral-900-level-6-right-opacity: 0.07;
$shadow-neutral-900-level-7-properties: 0 8px 50px 0;
$shadow-neutral-900-level-7-opacity: 0.08;
$shadow-primary-700-level-1-properties: 0 1px 5px 0;
$shadow-primary-700-level-1-opacity: 0.08;
$shadow-primary-700-level-2-properties: 0 1px 3px 0;
$shadow-primary-700-level-2-opacity: 0.36;
$shadow-primary-700-level-3-properties: 0 2px 5px 0;
$shadow-primary-700-level-3-opacity: 0.26;
$shadow-primary-700-level-4-properties: 0 2px 7px 0;
$shadow-primary-700-level-4-opacity: 0.2;
$shadow-primary-700-level-5-properties: 0 2px 10px 0;
$shadow-primary-700-level-5-opacity: 0.2;
$shadow-primary-700-level-6-properties: 0 8px 16px 0;
$shadow-primary-700-level-6-opacity: 0.15;
$shadow-primary-700-level-7-properties: 0 8px 50px 0;
$shadow-primary-700-level-7-opacity: 0.08;
$shadow-focused-level-1-properties: 0 0 0 2px;
$shadow-focused-level-1-opacity: 0.75;
$shadow-inner-neutral-900-level-1-properties: 0 2px 3px 0;
$shadow-inner-neutral-900-level-1-opacity: 0.06;
$shadow-inner-neutral-900-level-2-properties: 0 2px 3px 0;
$shadow-inner-neutral-900-level-2-opacity: 0.22;